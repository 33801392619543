import firebase from 'firebase';

export function convertToTimestamp(date?: firebase.firestore.Timestamp | string): string {
    if (!date) {
        return '';
    }

    if (typeof date === 'string') {
        return new Date(date as string).toLocaleString('et-EE');
    }

    return date.toDate().toLocaleString('et-EE');
}

export function getDoorStatus(doorStatus?: number) {
    if (doorStatus === 1) {
        return 'Open';
    }
    return 'Close';
}


export function getOccupancy(occupancyStatus?: number) {
    if (occupancyStatus === 100) {
        return 'Vacant';
    }
    return 'Occupied';
}